<template>
    <div
        class="grow-0 sm:rounded-lg overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-md w-full max-sm:bg-white max-sm:absolute max-sm:inset-0"
    >
        <button
            aria-label="Menü schließen"
            class="absolute size-9 bg-gray-100/70 hover:bg-gray-100 rounded-full top-4 right-4 flex text-gray-500 duration-100 sm:hidden"
            @click="loginModal = false"
        >
            <Icon name="close" class="m-auto" :size="22"></Icon>
        </button>

        <img src="~/assets/images/login.jpg" class="h-full object-cover object-center w-1/2 hidden md:block" />
        <FormWrapper
            ref="form"
            :keypress-enter="submitForm"
            autocomplete="false"
            class="grid gap-4 md:w-1/2 w-full self-center py-20 px-12 bg-white"
        >
            <FrontpageHeadline level="4">Anmelden</FrontpageHeadline>
            <FormInput
                v-model="formData.email"
                required
                focus
                name="email"
                label="E-Mail"
                placeholder="E-Mail"
                type="text"
            />
            <FormInput
                v-model="formData.password"
                required
                focus
                name="password"
                label="Passwort"
                placeholder="Passwort"
                type="password"
            />
            <FormCheckbox v-model="formData.remember" label="eingeloggt bleiben" name="keeplogged" />
            <Button :loading="loading" primary class="mt-4 w-full h-11" label="Anmelden" @click="submitForm" />
        </FormWrapper>
    </div>
</template>

<script setup>
defineProps({
    account: {
        type: Object,
        default: null,
    },
});

const form = ref(null);
const loading = ref(false);
const formData = reactive({
    email: '',
    password: '',
    remember: false,
});

async function submitForm() {
    loading.value = true;

    try {
        await $fetch('/api/account/login', {
            headers: useRequestHeaders(),
            method: 'POST',
            body: formData,
        });

        const basicAccount = await $fetch('/api/account/check', {
            headers: useRequestHeaders(['cookie']),
            method: 'POST',
        });

        const { account } = useStore();

        setTimeout(() => {
            loading.value = false;
            account.value = basicAccount;
            navigateTo('/account');
        }, 500);
    } catch (error) {
        loading.value = false;
        form.value.handleErrors(error.data.message);
    }
}
</script>
