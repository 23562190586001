<template>
    <div class="flex flex-col min-h-screen" :class="{ 'overflow-hidden h-screen': data.open || data.login }">
        <FrontpageHeader :account="account" :data="data" />
        <slot />

        <FrontpageFooter :account :data="data" />

        <Modal v-if="!account" v-model="data.login">
            <FrontpageLogin />
        </Modal>
    </div>
</template>

<script setup>
useHead({
    htmlAttrs: {
        lang: 'de',
    },
    bodyAttrs: {
        class: 'bg-white text-gray-800 antialiased',
    },
});

const { account } = useStore();

const data = reactive({
    open: false,
    login: false,
});
</script>
